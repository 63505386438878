import { useEffect, useState } from "react";
import {
  StepperComponent,
  GlobalCard,
  useWindowSize,
  useStyles,
  WCInlineInputFilter,
  WCInputFilter,
} from "@mfe/js-common-ave-uiutils";
import { useForm } from "@mantine/form";
import { FaCalendarDays, FaPlus } from "react-icons/fa6";
import { LuChevronDown } from "react-icons/lu";
import { FaMapMarkerAlt } from "react-icons/fa";

import {
  formatter,
  getAccessTokenApi,
  fetchVehicleData,
  fetchTraveLiquidatorByIDData,
  fetchTraveLiquidatorUpdateData,
  parseDateWihtFormat,
  filterEmpty,
  fetchCitiesData,
  lodash,
  getArrayByName,
  moment,
  fetchVehicleReservationDetail,
} from "@mfe/ts-common-ave-utilitaries";

import {
  useIsAuthenticated,
  MsalProvider,
  useMsal,
} from "@mfe/react-common-ave-msal";
import { VehicleCardComponent } from "../components/vehicleCard.component";

export default function BookVehicle({ organization, msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <BookVehicleComponent organization={organization} />
    </MsalProvider>
  );
}

export const BookVehicleComponent = ({ organization }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const { instance, accounts } = useMsal();
  const { classes } = useStyles();
  const isAuthenticated = useIsAuthenticated();
  const isMd = useWindowSize("md");

  const [tokenApi, setTokenApi] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [travelIndex, setTravelIndex] = useState(null);
  const [finalVehicle, setFinalVehicle] = useState(false);
  const [loadingWindow, setLoadingWindow] = useState(false);
  const [vehiclesSelected, setVehiclesSelected] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [errorImages, setErrorImages] = useState([]);
  const [data, setData] = useState({
    serviceId: "",
    lowerRate: "",
    higherRate: "",
    vehicleList: [],
    filterList: [],
    travelDestinations: [],
    travelLiquidatorData: {
      ViajeTrayecto: [],
      Multiviaje: "",
      TipoViajeId: "",
    },
  });

  const formTopFilter = useForm({
    initialValues: {
      destinationFilter: "",
      destinationSearchFilter: "",
      datesFilter: [null, null],
    },
  });

  const formRightFilter = useForm({
    initialValues: {
      transmission: [],
      doorCount: [],
      vehicleCategory: "",
    },
  });

  const topFilterData = [
    {
      md: "auto",
      inputType: "select",
      placeHolder: "Ciudad de destino",
      value: "destinationFilter",
      icon: <FaMapMarkerAlt size="1.5rem" color="#004236" />,
      form: formTopFilter.getInputProps("destinationFilter"),
      data: lodash(data?.travelDestinations).sortBy("label").value(),
      searchValue: formTopFilter.values.destinationSearchFilter,
      onSearchChange: (value) =>
        formTopFilter.setFieldValue("destinationSearchFilter", value),
      rightSection: <LuChevronDown size="1.2rem" color="#004236" />,
    },
    {
      md: "auto",
      inputType: "dateInput",
      placeHolder: "Seleccionar Fechas",
      value: "datesFilter",
      icon: <FaCalendarDays size="1.2rem" color="#004236" />,
      form: formTopFilter.getInputProps("datesFilter"),
    },
    {
      lg: 2,
      md: "content",
      inputType: "button",
      title: "Buscar",
      onClick: () => changeCity(),
    },
  ];

  const rightFilterData = [
    {
      title: "Categoría",
      inputType: "select",
      placeholder: "Categoría",
      data: getArrayByName(data?.filterList, "vehicleCategory") || [],
      value: "vehicleCategory",
      form: formRightFilter.getInputProps("vehicleCategory"),
      rightSection: formRightFilter.values.vehicleCategory ? (
        <FaPlus
          style={{
            transform: "rotate(45deg)",
            cursor: "pointer",
          }}
          onClick={() => formRightFilter.setFieldValue("vehicleCategory", "")}
        />
      ) : (
        <LuChevronDown size="1.2rem" color="#004236" />
      ),
    },
    {
      title: "Transmisión",
      inputType: "checkbox",
      data: getArrayByName(data?.filterList, "transmissionType") || [],
      value: "transmissionType",
      form: formRightFilter.getInputProps("transmission"),
    },
    {
      title: "Número de puertas",
      inputType: "checkbox",
      data: getArrayByName(data?.filterList, "doorCount") || [],
      value: "doorCount",
      form: formRightFilter.getInputProps("doorCount"),
    },
  ];

  const rightButtonsFilterData = [
    {
      title: "Trayecto sin Vehículo",
      inputType: "button",
      outline: true,
      onClick: () => handleClick({}),
    },
  ];

  const filterMapped = {
    vehicleCategory: formRightFilter.values.vehicleCategory && [
      formRightFilter.values.vehicleCategory,
    ],
    transmissionType: formRightFilter.values.transmission,
    doorCount: formRightFilter.values.doorCount,
  };

  const nextVehicleMap = data?.travelLiquidatorData?.ViajeTrayecto?.filter(
    (trip) =>
      trip?.ServicioBpo?.some((service) => service.AliasServicio === "ALQVEH")
  );

  const updateOriginalList = (original, modified) => {
    modified.forEach((modificado) => {
      const indice = original.findIndex(
        (original) => original.Id === modificado.Id
      );

      if (indice !== -1) {
        original[indice] = modificado;
      }
    });

    return original;
  };

  const changeCity = () => {
    setHasSearched(true);
    formRightFilter.reset();
    fetchVehicleData(tokenApi, setData, setLoading, setError, {
      userData,
      departureDate: parseDateWihtFormat(
        formTopFilter.values.datesFilter[0],
        "YYYY-MM-DD"
      ),
      returnDate: parseDateWihtFormat(
        formTopFilter.values.datesFilter[1],
        "YYYY-MM-DD"
      ),
      destination: formTopFilter.values.destinationFilter,
      filter: filterEmpty(filterMapped),
    });
  };

  const handleClick = async (item) => {
    const initialDate = hasSearched
      ? formTopFilter.values.datesFilter[0]
      : nextVehicleMap[travelIndex]?.FechaInicio;
    const finalDate = hasSearched
      ? formTopFilter.values.datesFilter[1]
      : nextVehicleMap[travelIndex]?.FechaFin;
    if (!!Object.keys(item).length) {
      await fetchVehicleReservationDetail(tokenApi, {
        vehicle: item,
        travel: nextVehicleMap[travelIndex],
        price: data?.travelLiquidatorData,
        higherRate: data?.higherRate,
        lowerRate: data?.lowerRate,
        initialDate,
        finalDate,
      });
    }

    const newVehicleMap = [...nextVehicleMap];
    const validateEmpty = !Object.keys(item).length;

    const serviceIndex = newVehicleMap[travelIndex].ServicioBpo?.findIndex(
      (servIndex) => servIndex.AliasServicio === "ALQVEH"
    );

    validateEmpty
      ? (newVehicleMap[travelIndex].ServicioBpo[serviceIndex] = filterEmpty({
          ...newVehicleMap[travelIndex].ServicioBpo[serviceIndex],
        }))
      : (newVehicleMap[travelIndex].ServicioBpo[serviceIndex] = {
          ...newVehicleMap[travelIndex].ServicioBpo[serviceIndex],
          Valor: item?.fares?.totalAmount,
          nombreServicio: item?.description?.split(":")[1],
          descripcionServicio: JSON.stringify({
            ...item,
            isCheapest:
              data?.vehicleList?.[0]?.fares?.totalAmount ===
              item?.fares?.totalAmount,
            initialDate: initialDate,
            finalDate: finalDate,
          }),
          imagenServicio: item?.image,
        });

    setVehiclesSelected(newVehicleMap);
    setHasSearched(false);

    const remainingIndexes = newVehicleMap
      .slice(travelIndex + 1)
      .findIndex((servIndex) =>
        servIndex.ServicioBpo.some(
          (service) => service.AliasServicio === "ALQVEH"
        )
      );

    if (remainingIndexes !== -1) {
      setTravelIndex(travelIndex + 1);
    } else {
      setFinalVehicle(true);
    }
  };

  const handleError = (index) => {
    const newImages = [...errorImages];
    newImages[index] = false;
    setErrorImages(newImages);
  };

  useEffect(() => {
    getAccessTokenApi(instance, accounts, setTokenApi);
  }, []);

  useEffect(() => {
    if (tokenApi) {
      fetchTraveLiquidatorByIDData(
        setData,
        tokenApi,
        localStorage.getItem("liquidationID"),
        setLoadingWindow
      );
    }
  }, [tokenApi]);

  useEffect(() => {
    formTopFilter.setValues({
      datesFilter: [
        moment(
          hasSearched
            ? formTopFilter.values.datesFilter[0]
            : nextVehicleMap[travelIndex]?.FechaInicio
        ).toDate(),
        moment(
          hasSearched
            ? formTopFilter.values.datesFilter[1]
            : nextVehicleMap[travelIndex]?.FechaFin
        ).toDate(),
      ],
      destinationFilter: hasSearched
        ? formTopFilter.values.destinationFilter
        : [
            nextVehicleMap[travelIndex]?.CodigoIatadestino,
            nextVehicleMap[travelIndex]?.ciudadDestinoGrupo,
            nextVehicleMap[travelIndex]?.nombreCiudadDestino,
          ].join("/"),
    });
  }, [nextVehicleMap[travelIndex]?.FechaFin]);

  useEffect(() => {
    if (tokenApi && nextVehicleMap.length) {
      const indice = nextVehicleMap
        .map((item) => item.ServicioBpo)
        .findIndex((service) =>
          service.some((s) => s.AliasServicio === "ALQVEH")
        );

      setTravelIndex(indice);
    }
  }, [tokenApi, data?.travelLiquidatorData]);

  useEffect(() => {
    if (travelIndex !== null) {
      fetchCitiesData(
        setData,
        tokenApi,
        data?.travelLiquidatorData?.TipoViajeId,
        parseDateWihtFormat(
          nextVehicleMap[travelIndex]?.FechaFin,
          "YYYY-MM-DD"
        ),
        false
      );
      fetchVehicleData(tokenApi, setData, setLoading, setError, {
        userData,
        departureDate: parseDateWihtFormat(
          nextVehicleMap[travelIndex]?.FechaInicio,
          "YYYY-MM-DD"
        ),
        returnDate: parseDateWihtFormat(
          nextVehicleMap[travelIndex]?.FechaFin,
          "YYYY-MM-DD"
        ),
        destination: hasSearched
          ? formTopFilter.values.destinationFilter
          : nextVehicleMap[travelIndex]?.CodigoIatadestino,
        filter: filterEmpty(filterMapped),
      });
    }
  }, [travelIndex, formRightFilter.values]);

  useEffect(() => {
    if (finalVehicle) {
      fetchTraveLiquidatorUpdateData(
        tokenApi,
        {
          ...data?.travelLiquidatorData,
          ViajeTrayecto: updateOriginalList(
            data?.travelLiquidatorData?.ViajeTrayecto,
            vehiclesSelected
          ),
        },
        setLoadingWindow
      );
    }
  }, [finalVehicle]);

  const orderedList =
    lodash.orderBy(
      data?.vehicleList,
      [(obj) => parseFloat(obj?.fares?.totalAmount)],
      ["des"]
    ) || [];

  const utils = {
    formatter,
  };

  const vehicleCardComponentProps = {
    classes,
    data,
    isMd,
    loading,
    utils,
    error,
    StepperComponent,
    GlobalCard,
    WCInlineInputFilter,
    WCInputFilter,
    loadingWindow,
    topFilterData,
    rightFilterData,
    rightButtonsFilterData,
    handleError,
    orderedList,
    handleClick,
    travelIndex,
    errorImages,
    nextVehicleMap,
    formTopFilter,
    hasSearched,
  };

  return isAuthenticated ? (
    <VehicleCardComponent {...vehicleCardComponentProps} />
  ) : null;
};
