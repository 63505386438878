import {
  Box,
  Flex,
  Text,
  Loader,
  Grid,
  Image,
  Divider,
  Button,
  LoadingOverlay,
} from "@mantine/core";
import { MdHideImage } from "react-icons/md";
import { capitalize } from "lodash";

export const VehicleCardComponent = ({
  classes,
  data,
  isMd,
  loading,
  utils,
  error,
  StepperComponent,
  GlobalCard,
  WCInlineInputFilter,
  WCInputFilter,
  loadingWindow,
  topFilterData,
  rightFilterData,
  rightButtonsFilterData,
  handleError,
  orderedList,
  handleClick,
  travelIndex,
  errorImages,
  nextVehicleMap,
  formTopFilter,
  hasSearched,
}) => {
  if (loadingWindow) {
    return (
      <LoadingOverlay
        visible={loadingWindow}
        overlayBlur={50}
        loaderProps={{ color: "#cbd300" }}
      />
    );
  }

  return (
    <>
      <Box maw={{ xs: "98%", md: "90%", lg: "80%" }} mx="auto" py="16px">
        <StepperComponent servicesData={data?.travelLiquidatorData} />
      </Box>

      {loading ? (
        <Grid maw={{ xs: "100%", md: "90%", lg: "80%" }} mx="auto">
          <Grid.Col xs={12}>
            <GlobalCard>
              <Flex
                gap="md"
                justify="center"
                align="center"
                direction="row"
                wrap="wrap"
                mih={isMd ? "auto" : "208px"}
              >
                <Text size="md" fw={700}>
                  Cargando Vehículos disponibles en GDS...
                </Text>
                <Loader variant="dots" color="#cbd300" />
              </Flex>
            </GlobalCard>
          </Grid.Col>
        </Grid>
      ) : error.length > 0 ? (
        <Grid maw={{ xs: "100%", md: "90%", lg: "80%" }} mx="auto">
          <Grid.Col xs={12}>
            <GlobalCard>
              <Flex
                gap={12}
                justify="center"
                align="center"
                direction="column"
                sx={{ height: isMd ? "auto" : "224px" }}
              >
                <Text fw={700} color="#004236" ta="center">
                  {`${capitalize(error)} para la ciudad de ${
                    hasSearched
                      ? formTopFilter.values.destinationFilter.split[2]
                      : nextVehicleMap[travelIndex]?.nombreCiudadDestino
                  }`}
                </Text>
                <Button
                  className={classes.buttonOutline}
                  data-testid="skip-vehicle-button"
                  onClick={() => handleClick({})}
                >
                  Trayecto sin Vehículo
                </Button>
              </Flex>
            </GlobalCard>
          </Grid.Col>
        </Grid>
      ) : (
        <>
          <WCInlineInputFilter inputs={topFilterData} scrollY="157" />
          <Grid maw={{ xs: "100%", md: "90%", lg: "80%" }} mx="auto">
            <Grid.Col xs={12} md={3} orderSm={1} orderMd={2} orderLg={2}>
              <Box
                sx={{
                  position: "sticky",
                  top: 85,
                }}
              >
                <GlobalCard
                  styles={{
                    padding: "1.25rem .5rem",
                  }}
                >
                  <WCInputFilter
                    inputs={rightFilterData}
                    buttons={rightButtonsFilterData}
                  />
                </GlobalCard>
              </Box>
            </Grid.Col>
            <Grid.Col xs={12} md={9} orderSm={2} orderMd={1} orderLg={1}>
              {orderedList.map((vehicle, index) => (
                <GlobalCard key={index}>
                  <Grid
                    sx={{
                      display: "flex",
                      height: isMd ? "auto" : "224px",
                      alignItems: "center",
                    }}
                  >
                    <Grid.Col md={3} xs={12}>
                      {errorImages[index] !== false ? (
                        <Image
                          radius="md"
                          height={180}
                          src={vehicle.image}
                          onError={() => handleError(index)}
                        />
                      ) : (
                        <Flex align="center" justify="center" h="100%">
                          <MdHideImage size="2rem" color="#004236" />
                        </Flex>
                      )}
                      <Text fw={700} color="#004236" ta="center">
                        {vehicle.nameOperatingCar}
                      </Text>
                    </Grid.Col>
                    <Grid.Col xs={12} md={6} sx={{ justifySelf: "center" }}>
                      <Text fw={700} size="lg">
                        {vehicle.description.split(":")[1]}
                      </Text>
                      <Text>{vehicle.car.passengerQuantity} pasajeros.</Text>
                      <Text>{vehicle.car.doorCount} puertas.</Text>
                      <Text>
                        {vehicle.car.baggageQuantity}
                        {Number(vehicle.car.baggageQuantity) > 1
                          ? " espacios"
                          : " espacio"}
                        {` para equipaje`}
                      </Text>
                      <Text>
                        {vehicle.car.airCondition
                          ? "Con aire acondicionado."
                          : "Sin aire acondicionado."}
                      </Text>
                      <Text>
                        Transmisión
                        {vehicle.car.transmissionType === "Automatic"
                          ? " Automática"
                          : " Manual"}
                      </Text>
                    </Grid.Col>
                    <Divider orientation="vertical" />
                    <Grid.Col xs={12} md="auto">
                      <Text ta="center">Tarifa Total</Text>
                      <Text fw={700} fz="lg" ta="center" color="#004236">
                        {vehicle.fares.currency === "COP" &&
                          utils.formatter.format(vehicle.fares.totalAmount)}
                      </Text>
                      <Button
                        fullWidth
                        className={classes.button}
                        mt={16}
                        data-testid="select-vechicle-button"
                        onClick={() => handleClick(vehicle)}
                      >
                        Seleccionar
                      </Button>
                    </Grid.Col>
                  </Grid>
                </GlobalCard>
              ))}
            </Grid.Col>
          </Grid>
        </>
      )}
    </>
  );
};
